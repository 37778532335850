import React from 'react';
import './Recruitment.css'; // Add any specific styling you may need
import { useNavigate } from 'react-router-dom';

export const Recruitment = () => {
    const navigation = useNavigate()
    return (
        <>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            <h3 className="mb-4">Our endeavor is to offer IT Solution with a Human Touch</h3>
                            <h1 className="display-4 fw-medium">Recruitment Services</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-5">
    <div className="row">
        <div className="col-lg-8">
            <h2 className="mb-4">Our Recruitment Services</h2>
            <ul>
                <li>
                    First Step specializes in offering recruitment services for both technical and non-technical roles across various industries, including IT, Non-IT, Education & E-Learning, Healthcare, BPO, and Construction.
                </li>
                <li>
                    We have extensive access to a wide array of databases, containing a vast pool of up-to-date candidate profiles.
                </li>
                <li>
                    Our recruitment practice follows a well-structured and systematic selection methodology.
                </li>
                <li>
                    Our team excels at identifying candidates with the right skills and experience to deliver impactful results and drive success.
                </li>
                <li>
                    Our efficient placement process ensures that consultants are onboarded within the required timeframe, enabling our clients to stay focused on their core operations. We prioritize maintaining complete confidentiality of client information.
                </li>
            </ul>

            <h2 className="mb-4 mt-4">Recruitment Services – IT Skills</h2>
            <p>We offer specialized recruitment services in the following IT domains:</p>
            <ul>
                <li>Mainframes / Legacy Applications</li>
                <li>SAP Professionals / ABAP Developers</li>
                <li>Web Applications</li>
                <li>JavaJ2EE Specialists</li>
                <li>Microsoft Technology Experts</li>
                <li>Software Testing Professionals</li>
            </ul>

            <h2 className="mb-4 mt-4">Recruitment Services – Non-IT Skills</h2>
            <p>Our non-IT recruitment services focus on the following areas:</p>
            <ul>
                <li>Senior Management Positions</li>
                <li>Engineering Domain Roles</li>
                <li>Sales & Marketing Professionals</li>
                <li>Administrative & Support Roles (e.g., Secretary, Administrator, Receptionist, etc.)</li>
                <li>BPO Positions (including Telemarketing, CSA, CSE, CSR, Back Office, Collections, Voice, Chat, Email, and Blended Processes)</li>
                <li>Pharma</li>
            </ul>
        </div>

        <div className="col-lg-4 d-flex flex-column align-items-center">
            <img
                src={require('../assets/Recruitment-Services.jpg')} // Replace with your image URL
                alt="Office Environment"
                className="img-fluid rounded mb-3 mt-3"
            />
            <button onClick={() => navigation('/current-opening')} className="btn btn-danger btn-lg mt-3">
                View Current Openings
            </button>
        </div>
    </div>
</div>

            <footer className="footer bg-light text-center py-3">
                <p>First Step Technologies Pvt. Ltd. © Copyright 2024</p>
            </footer>
        </>
    );
};
