import React, { useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import CandidateList from './CandidateList';
import HRList from './HRList';
// import './admin.css'; // Ensure this CSS exists
import AddJobOpening from './AddJobOpening';
import JobOpeningList from './JobOpeningList';
import JobDetails from './JobDetails';
import Header from '../Header';
import Footer from '../Footer';
import SideNav from '../SideNav';
import Dashboard from './Dashboard';
import UpdateJobOpening from './UpdateJobOpening';
import UpdateHRForm from './UpdateHRForm';
import UpdateCandidateForm from './UpdateCandidateForm';


const AdminPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <Header toggleSidebar={toggleSidebar} />
      <div className="">
        <SideNav isSidebarOpen={isSidebarOpen} /> {/* Sidebar */}
        <main className="mt-5">
          {/* Define your routes here */}
          <Routes>
            <Route path="dashboard" element={<Dashboard />} /> {/* Dashboard or Home */}
            <Route path="candidates" element={<CandidateList />} />
            <Route path="update-candidate-form/:id" element={<UpdateCandidateForm />} />
            <Route path="hr-forms" element={<HRList />} />
            <Route path="update-hr-form/:id" element={<UpdateHRForm />} />
            <Route path="add-job-opening" element={<AddJobOpening />} />
            <Route path="update-job-opening/:id" element={<UpdateJobOpening />} />
            <Route path="job-openings" element={<JobOpeningList />} />
            <Route path="job-details/:id" element={<JobDetails />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default AdminPanel;
