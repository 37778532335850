import React from "react";
export const Contact = () => {
  return (
    <>
      <section className="about-section mt-5 pt-3">
        <div className="overlay">
          <div
            className="container d-flex align-items-center text-white"
            style={{ height: "50vh" }}
          >
            <div>
              <h3 className="mb-4">Contact Us</h3>
              <h1 className="display-4 fw-medium">
                We’d love to hear from you.
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6">
            <h2>Connect with Recruiters and Career Resources Today!</h2>
            <h4>Write to Us</h4>
            <p>
              For Queries, Please Write To:{" "}
              <a href="mailto:iqbal@firststepserviece.com">
                iqbal@firststepserviece.com
              </a>
            </p>

            <h5>Pune</h5>
            <p>First Step Services Pvt. Ltd.</p>
            <p>
              Dharmavat Nivas office no 07, 1st floor above icici bank ATM – near
              Talab masjid Kondhwa Khurd Pune 411048
            </p>
            <p>
              Phone No.: <a href="tel:+918208418004">+91-8208418004</a>
            </p>
          </div>

          <div className="col-md-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.471053190917!2d73.88949629999999!3d18.4623103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb8825aac681%3A0x85fe3676083c64c9!2sVIJAY%20SALES%20-%20KONDHWA!5e0!3m2!1sen!2sin!4v1729533400053!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <footer className="footer bg-light text-center py-3">
        <p>First Step Technologies Pvt. Ltd. © Copyright 2024</p>
      </footer>
    </>
  );
};
