import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is included

const Pagination = ({ currentPage, totalPages, itemsPerPage, totalRecords, onPageChange, onItemsPerPageChange }) => {
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handlePageClick = (page) => {
        if (page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center mt-4">
            {/* Rows per page dropdown */}
            <div className="d-flex align-items-center">
                <span>
                    Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, totalRecords)} of {totalRecords} rows
                </span>
                <select
                    value={itemsPerPage}
                    onChange={(e) => onItemsPerPageChange(parseInt(e.target.value))}
                    className="ml-3 form-control"
                    style={{ width: 'auto', display: 'inline-block' }}
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>

            {/* Page navigation buttons */}
            <ul className="pagination mb-0">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handlePreviousPage}>&lt;</button>
                </li>
                {[...Array(totalPages).keys()].map((_, index) => {
                    const page = index + 1;
                    if (page === currentPage || page === 1 || page === totalPages || (page >= currentPage - 1 && page <= currentPage + 1)) {
                        return (
                            <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageClick(page)}>{page}</button>
                            </li>
                        );
                    } else if (page === currentPage - 2 || page === currentPage + 2) {
                        return (
                            <li key={page} className="page-item">
                                <span className="page-link">...</span>
                            </li>
                        );
                    }
                    return null;
                })}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={handleNextPage}>&gt;</button>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
