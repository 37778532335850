import React, { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './navigation.css';

export const Navigation = () => {
    const location = useLocation();

    // Check if any of the careers dropdown items are active
    const isCareersActive = location.pathname === "/candidate-form" || location.pathname === "/hr-form";
    const navbarCollapseRef = useRef(null); // Reference to collapse

    const closeNavbar = () => {
        // Manually toggle the collapse by triggering the Bootstrap collapse hide method
        const collapse = new window.bootstrap.Collapse(navbarCollapseRef.current, {
            toggle: true,
        });
        collapse.hide(); // Hide the collapsed menu after selection
    };
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <div className="container">
                <NavLink className="navbar-brand" to="/">
                    <img
                        src={require('../assets/logo.jpg')}
                        alt="Logo"
                        style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                    />
                </NavLink>
                <div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav" ref={navbarCollapseRef}>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <NavLink exact className="nav-link" to="/" onClick={closeNavbar}>
                                Home
                            </NavLink>
                        </li>

                        <li className="nav-item" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <NavLink className="nav-link" to="/about" onClick={closeNavbar}>
                                About Us
                            </NavLink>
                        </li>
                        <li className="nav-item" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <NavLink className="nav-link" to="/recruitment-service" onClick={closeNavbar}>
                                Recruitment Services
                            </NavLink>
                        </li>
                        <li className="nav-item" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <NavLink className="nav-link" to="/current-opening" onClick={closeNavbar}>
                                Current Opening
                            </NavLink>
                        </li>
                        {/* Careers Dropdown */}
                        <li className="nav-item dropdown" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Careers
                            </a>
                            <ul className="dropdown-menu">
                                <li style={{ color: '#000000', fontWeight: 'bold' }}>
                                    <NavLink className="dropdown-item" to="/candidate-form" onClick={closeNavbar}>
                                        Candidate Form
                                    </NavLink>
                                </li>
                                <li style={{ color: '#000000', fontWeight: 'bold' }}>
                                    <NavLink className="dropdown-item" to="/hr-form" onClick={closeNavbar}>
                                        HR Form
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item" style={{ color: '#000000', fontWeight: 'bold' }}>
                            <NavLink className="nav-link" to="/contact-us" onClick={closeNavbar}>
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
