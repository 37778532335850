import React, { useState, useEffect } from 'react';
import { postApiCall } from '../../utils/commonFunctions';
import Pagination from '../Pagination';
import { CSVLink } from 'react-csv';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const CandidateList = () => {
  const [candidates, setCandidates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    
    fetchCandidates();
  }, [currentPage, itemsPerPage, searchQuery]); // Re-fetch candidates when `currentPage`, `itemsPerPage`, or `searchQuery` changes
  const fetchCandidates = async () => {
    try {
      const response = await postApiCall('/candidateform/index', {
        page: currentPage,
        limit: itemsPerPage,
        search: searchQuery, // Add search query to API call
      });

      if (response.data.status === 200) {
        setCandidates(response.data.data);
        setTotalPages(response.data.pagination.total_pages); // Assuming response contains pagination details
        setTotalRecords(response.data.pagination.total_records); // Assuming response contains pagination details
      } else {
        console.error('Failed to fetch candidates');
      }
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  // Handler for search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handler for page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for changing items per page
  const handleItemsPerPageChange = (newLimit) => {
    setItemsPerPage(newLimit);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const navigate = useNavigate();

  const handleUpdate = (id) => {
    navigate(`/admin/update-candidate-form/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this candidate?');
    if (confirmDelete) {
      try {
        const response = await postApiCall(`/candidateform/delete`, { id }, 'DELETE');
        if (response.data.status === 200) {
          setCandidates(candidates.filter((candidate) => candidate.id !== id));
          fetchCandidates()
          toast.success('Candidate deleted successfully');
          console.log(`Deleted candidate with ID: ${id}`);
        } else {
          console.error('Failed to delete candidate');
        }
      } catch (error) {
        console.error('Error deleting candidate:', error);
      }
    }
  };

  // Define CSV headers and data
  const csvHeaders = [
    { label: 'Sr. No', key: 'srNo' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Post', key: 'post' },
    { label: 'City', key: 'city' },
    { label: 'Area', key: 'area' },
    { label: 'Experience (years)', key: 'experience' },
    { label: 'Qualification', key: 'qualification' },
    { label: 'Specialization', key: 'specialization' },
    { label: 'Certifications', key: 'certifications' },
    { label: 'Key Skills', key: 'key_skills' },
  ];

  const csvData = candidates.map((candidate, index) => ({
    srNo: index + 1,
    ...candidate,
  }));

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Candidate List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Search and CSV Export */}
      

      {/* Candidate List */}
      <section className="content">
      <div className="row mb-3">
        <div className="col-md-8 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Search by Name, Post, or City"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="col-md-4 text-end">
          <CSVLink
            headers={csvHeaders}
            data={csvData}
            filename="candidates_list.csv"
            className="btn btn-success"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Post</th>
                <th>City</th>
                <th>Area</th>
                <th>Experience</th>
                <th>Qualification</th>
                <th>Specialization</th>
                <th>Certifications</th>
                <th>Key Skills</th>
                <th>Resume</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {candidates.map((candidate, index) => (
                <tr key={candidate.id}>
                  <td>{index + 1}</td>
                  <td>{`${candidate.firstName} ${candidate.lastName}`}</td>
                  <td>{candidate.email}</td>
                  <td>{candidate.phone}</td>
                  <td>{candidate.post}</td>
                  <td>{candidate.city}</td>
                  <td>{candidate.area}</td>
                  <td>{candidate.experience} years</td>
                  <td>{candidate.qualification}</td>
                  <td>{candidate.specialization}</td>
                  <td>{candidate.certifications || 'N/A'}</td>
                  <td>{candidate.key_skills || 'N/A'}</td>
                  <td>
                    {candidate.resume ? (
                      <a href={process.env.REACT_APP_FILE_URL + candidate.resume} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">
                        View Resume
                      </a>
                    ) : (
                      'No resume'
                    )}
                  </td>
                  <td>
                    <FaEdit className="me-3 text-warning" style={{ cursor: 'pointer' }} onClick={() => handleUpdate(candidate.id)} title="Edit" />
                    <FaTrash className="text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(candidate.id)} title="Delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </section>
    </div>
  );
};

export default CandidateList;
