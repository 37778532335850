import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // For accessing URL params and navigation
import toast from "react-hot-toast";
import { postApiCall } from "../../utils/commonFunctions";

export const UpdateHRForm = () => {
    const { id } = useParams(); // Get the HR form ID from the URL params
    const navigate = useNavigate(); // For navigation after submission

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        companyName: "",
        designation: "",
        location: "",
        requirement: "",
        otherRequirement: "",
        candidatesNeeded: "",
    });

    const [loading, setLoading] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [errors, setErrors] = useState({}); // For validation errors

    // Fetch HR form data when the component loads
    useEffect(() => {
        const fetchHRForm = async () => {
            try {
                const response = await postApiCall(`/hrform/show`, { id });
                if (response.data.status === 200) {
                    setFormData(response.data.data); // Populate formData with the fetched data
                    if (response.data.data.requirement === "Other") {
                        setIsOther(true); // Show otherRequirement field if the requirement is 'Other'
                    }
                } else {
                    toast.error("Failed to fetch HR form details.");
                }
            } catch (error) {
                console.error("Error fetching HR form:", error);
                toast.error("Error fetching HR form details.");
            }
        };

        fetchHRForm();
    }, [id]);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone" && value.length > 10) {
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle requirement change for "Other" option
    const handleRequirementChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({ ...prevData, requirement: value }));

        if (value === "Other") {
            setIsOther(true);
        } else {
            setIsOther(false);
        }
    };

    // Validate form before submitting
    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.name) {
            tempErrors.name = "Name is required";
            isValid = false;
        }

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "A valid email is required";
            isValid = false;
        }

        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            tempErrors.phone = "Phone number must be 10 digits";
            isValid = false;
        }

        if (!formData.companyName) {
            tempErrors.companyName = "Company Name is required";
            isValid = false;
        }

        if (!formData.designation) {
            tempErrors.designation = "Designation is required";
            isValid = false;
        }

        if (!formData.location) {
            tempErrors.location = "Location is required";
            isValid = false;
        }

        if (!formData.requirement) {
            tempErrors.requirement = "Requirement is required";
            isValid = false;
        }

        if (isOther && !formData.otherRequirement) {
            tempErrors.otherRequirement = "Other Requirement is required";
            isValid = false;
        }

        if (!formData.candidatesNeeded || formData.candidatesNeeded <= 0) {
            tempErrors.candidatesNeeded = "Candidates Needed must be greater than 0";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    // Handle form submission for updating
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form before submission
        if (!validateForm()) {
            return;
        }

        setLoading(true); // Disable the submit button by setting loading to true

        try {
            // Make the API call
            const response = await postApiCall(`/hrform/update`, { ...formData, id });

            if (response.data.status === 200) {
                toast.success("HR Form Updated Successfully!");
                navigate("/admin/hr-forms"); // Navigate back to the HR forms list after successful update
            } else if (response.data.status === 409) {
                // Handle validation or conflict errors (409 Conflict)
                setErrors(response.data.errors); // Assuming the errors are returned in the 'errors' field
                toast.error("There were validation errors. Please check your input.");
            } else {
                toast.error("Failed to update the form.");
            }
        } catch (error) {
            console.error("Error updating form:", error);
            toast.error("An error occurred while updating the form.");
        } finally {
            setLoading(false); // Re-enable the submit button
        }
    };


    return (
        <>
            <div className="content-wrapper mt-2">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Update Hr Form</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <div className="content">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.name && <small className="text-danger">{errors.name}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.email && <small className="text-danger">{errors.email}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="companyName" className="form-label">Company Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.companyName && <small className="text-danger">{errors.companyName}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="designation" className="form-label">Designation</label>
                            <input
                                type="text"
                                className="form-control"
                                id="designation"
                                name="designation"
                                value={formData.designation}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.designation && <small className="text-danger">{errors.designation}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">Location</label>
                            <input
                                type="text"
                                className="form-control"
                                id="location"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.location && <small className="text-danger">{errors.location}</small>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="requirement" className="form-label">Requirement</label>
                            <select
                                className="form-select"
                                id="requirement"
                                name="requirement"
                                value={formData.requirement}
                                onChange={handleRequirementChange}
                                required
                            >
                                <option value="">Select Requirement</option>
                                <option value="Software Engineer">Software Engineer</option>
                                <option value="Data Analyst">Data Analyst</option>
                                <option value="Project Manager">Project Manager</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.requirement && <small className="text-danger">{errors.requirement}</small>}
                        </div>

                        {isOther && (
                            <div className="mb-3">
                                <label htmlFor="otherRequirement" className="form-label">Other Requirement</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="otherRequirement"
                                    name="otherRequirement"
                                    value={formData.otherRequirement}
                                    onChange={handleInputChange}
                                    required
                                />
                                {errors.otherRequirement && <small className="text-danger">{errors.otherRequirement}</small>}
                            </div>
                        )}

                        <div className="mb-3">
                            <label htmlFor="candidatesNeeded" className="form-label">How many candidates do you need?</label>
                            <input
                                type="number"
                                className="form-control"
                                id="candidatesNeeded"
                                name="candidatesNeeded"
                                value={formData.candidatesNeeded}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.candidatesNeeded && <small className="text-danger">{errors.candidatesNeeded}</small>}
                        </div>

                        <button type="submit" className="btn btn-primary" disabled={loading}>
                            {loading ? "Updating..." : "Update HR Form"}
                        </button>
                    </form>

                </div>
            </div>
        </>
    );
};

export default UpdateHRForm;
