import React, { useState } from 'react';
import axios from 'axios';
import { postApiCall } from '../../utils/commonFunctions';
import toast from 'react-hot-toast';

const AddJobOpening = () => {
    const [formData, setFormData] = useState({
        jobTitle: '',
        jobDescription: '',
        location: '',
        experience: '',
        jobType: '',
        education: '',
        numberOfVacancies: '',
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Add loading state

    // Validation logic
    const validate = () => {
        const newErrors = {};
        if (!formData.jobTitle.trim()) newErrors.jobTitle = 'Job title is required';
        if (!formData.jobDescription.trim()) newErrors.jobDescription = 'Job description is required';
        if (!formData.location.trim()) newErrors.location = 'Location is required';
        if (!formData.experience) newErrors.experience = 'Experience is required';
        if (!formData.jobType) newErrors.jobType = 'Job type is required';
        if (!formData.numberOfVacancies || formData.numberOfVacancies <= 0) newErrors.numberOfVacancies = 'Number of vacancies must be greater than 0';
        return newErrors;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data on the client side (optional)
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors); // Set client-side validation errors
            return;
        }

        setLoading(true); // Set loading to true before API call

        try {
            // API call to submit the form data
            const response = await postApiCall('/jobs', formData);

            if (response.data.status === 201) {
                // Successful response
                toast.success('Job opening has been added successfully!');
                setFormData({
                    jobTitle: '',
                    jobDescription: '',
                    location: '',
                    experience: '',
                    jobType: '',
                    education: '',
                    numberOfVacancies: '',
                });
            } else if (response.data.status === 409) {
                // Handle validation errors from backend
                setErrors(response.data.errors); // Set validation errors from the backend
                toast.error('There were validation errors. Please check your input.');
            } else {
                toast.error('Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            setLoading(false); // Set loading to false after the API call is finished
        }
    };


    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Add New Job Opening</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content p-4">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="jobTitle" className="form-label">Job Title*</label>
                        <input
                            type="text"
                            className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                            id="jobTitle"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.jobTitle && <div className="invalid-feedback">{errors.jobTitle}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="jobDescription" className="form-label">Job Description*</label>
                        <textarea
                            className={`form-control ${errors.jobDescription ? 'is-invalid' : ''}`}
                            id="jobDescription"
                            name="jobDescription"
                            rows="4"
                            value={formData.jobDescription}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                        {errors.jobDescription && <div className="invalid-feedback">{errors.jobDescription}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">Location*</label>
                        <input
                            type="text"
                            className={`form-control ${errors.location ? 'is-invalid' : ''}`}
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.location && <div className="invalid-feedback">{errors.location}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="experience" className="form-label">Experience Required (in years)*</label>
                        <input
                            type="number"
                            className={`form-control ${errors.experience ? 'is-invalid' : ''}`}
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.experience && <div className="invalid-feedback">{errors.experience}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="jobType" className="form-label">Job Type*</label>
                        <select
                            className={`form-select ${errors.jobType ? 'is-invalid' : ''}`}
                            id="jobType"
                            name="jobType"
                            value={formData.jobType}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Job Type</option>
                            <option value="Full-time">Full-time</option>
                            <option value="Part-time">Part-time</option>
                            <option value="Contract">Contract</option>
                        </select>
                        {errors.jobType && <div className="invalid-feedback">{errors.jobType}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="education" className="form-label">Education Requirement</label>
                        <input
                            type="text"
                            className="form-control"
                            id="education"
                            name="education"
                            value={formData.education}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="numberOfVacancies" className="form-label">Number of Vacancies*</label>
                        <input
                            type="number"
                            className={`form-control ${errors.numberOfVacancies ? 'is-invalid' : ''}`}
                            id="numberOfVacancies"
                            name="numberOfVacancies"
                            value={formData.numberOfVacancies}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.numberOfVacancies && <div className="invalid-feedback">{errors.numberOfVacancies}</div>}
                    </div>

                    <button type="submit" className="btn btn-primary mb-4" disabled={loading}>
                        {loading ? 'Submitting...' : 'Add Job Opening'} {/* Change button text when loading */}
                    </button>
                </form>
            </section>
        </div>
    );
};

export default AddJobOpening;
