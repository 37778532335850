import React from 'react';
import { useParams } from 'react-router-dom';

const JobDetails = () => {
  const { id } = useParams();
  
  // In real use case, fetch job details from an API using the ID
  return (
    <div className="container mt-5">
      <h2>Job Details for Job ID: {id}</h2>
      <p>More detailed information will be displayed here.</p>
    </div>
  );
};

export default JobDetails;
