import React, { useState, useEffect } from 'react';
import { FaEye, FaEdit, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa'; // Font Awesome Icons
import { useNavigate } from 'react-router-dom';
import { postApiCall } from '../../utils/commonFunctions';
import toast from 'react-hot-toast';
import Pagination from '../Pagination';

const JobOpeningList = () => {
    const [jobOpenings, setJobOpenings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page
    const [searchQuery, setSearchQuery] = useState(''); // Search query

    const navigate = useNavigate(); // Use the navigate hook

    // Fetch job openings from API when the component loads or currentPage/itemsPerPage/searchQuery changes
    useEffect(() => {
        
        fetchJobOpenings();
    }, [currentPage, itemsPerPage, searchQuery]); // Add searchQuery as a dependency
    const fetchJobOpenings = async () => {
        try {
            const response = await postApiCall('/jobs/index', {
                page: currentPage,
                limit: itemsPerPage,
                search: searchQuery, // Pass the search query to the API
            });

            // Check if the response has the data we need
            if (response.data.status === 200) {
                setJobOpenings(response.data.data); // Array of job openings
                setTotalPages(response.data.pagination.total_pages); // Total number of pages
                setTotalRecords(response.data.pagination.total_records); // Total number of pages
            } else {
                // Handle the case where the pagination or data is missing
                console.error('Unexpected response format:', response);
                toast.error('Failed to fetch pagination data.');
            }
        } catch (error) {
            console.error('Error fetching job openings:', error);
        }
    };

    const handleAddJob = () => {
        navigate('/admin/add-job-opening');
    };

    const handleDelete = async (id) => {
        try {
            const response = await postApiCall('/jobs/delete', { id });
            if (response.data.status === 200) {
                setJobOpenings(jobOpenings.filter(job => job.id !== id));
                fetchJobOpenings()
                toast.success('Job deleted successfully');
            }
        } catch (error) {
            console.error('Error deleting job:', error);
            toast.error('Failed to delete job. Please try again.');
        }
    };

    const handleStatusChange = async (id) => {
        const job = jobOpenings.find(job => job.id === id);
        const updatedStatus = job.status === 'Active' ? 'Inactive' : 'Active';

        try {
            const response = await postApiCall('/jobs/changeStatus', { id, status: updatedStatus });
            if (response.data.status === 200) {
                setJobOpenings(jobOpenings.map(job =>
                    job.id === id ? { ...job, status: updatedStatus } : job
                ));
                toast.success('Job status updated successfully');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update job status. Please try again.');
        }
    };

    const handleUpdate = (id) => {
        navigate(`/admin/update-job-opening/${id}`);
        console.log(`Update job with ID: ${id}`);
    };

    const handleView = (id) => {
        console.log(`View job details with ID: ${id}`);
    };

    // Pagination handlers
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to the first page after changing items per page
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Job Openings</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4 d-flex justify-content-between">
                <div className="col-6 col-sm-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Job Title, Description, or Location"
                        value={searchQuery}
                        onChange={handleSearchChange} // Search input handler
                    />
                </div>
                <div className="col-3 col-sm-2">
                    <button className="btn btn-primary" onClick={handleAddJob}>
                        Add New Job
                    </button>
                </div>
            </div>

            <section className="content">
                {/* Jobs Table */}
                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead className="thead-light">
                            <tr>
                                <th>Sr. No.</th>
                                <th>Job Title</th>
                                <th>Location</th>
                                <th>Experience</th>
                                <th>Description</th>
                                <th>Vacancies</th>
                                {/* <th>Status</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobOpenings.map((job, index) => (
                                <tr key={job.id}>
                                    <th>{(currentPage - 1) * itemsPerPage + index + 1}</th>
                                    <td>{job.jobTitle}</td>
                                    <td>{job.location}</td>
                                    <td>{job.experience}</td>
                                    <td>{job.jobDescription}</td>
                                    <td>{job.numberOfVacancies}</td>
                                    {/* <td>
                                        <span className={job.status === 'Active' ? 'text-success' : 'text-danger'}>
                                            {job.status}
                                        </span>
                                    </td> */}
                                    <td>
                                        {/* <FaEye className="me-3 text-primary" style={{ cursor: 'pointer' }} onClick={() => handleView(job.id)} title="View" /> */}
                                        <FaEdit className="me-3 text-warning" style={{ cursor: 'pointer' }} onClick={() => handleUpdate(job.id)} title="Edit" />
                                        {/* {job.status === 'Active' ? (
                                            <FaToggleOn className="me-3 text-success" style={{ cursor: 'pointer' }} onClick={() => handleStatusChange(job.id)} title="Deactivate" />
                                        ) : (
                                            <FaToggleOff className="me-3 text-secondary" style={{ cursor: 'pointer' }} onClick={() => handleStatusChange(job.id)} title="Activate" />
                                        )} */}
                                        <FaTrash className="text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(job.id)} title="Delete" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Pagination Controls */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    itemsPerPage={itemsPerPage}
                    totalRecords={totalRecords}
                    onPageChange={(page) => setCurrentPage(page)}
                    onItemsPerPageChange={(limit) => setItemsPerPage(limit)}
                />
            </section>
        </div>
    );
};

export default JobOpeningList;
