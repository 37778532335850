import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // For accessing URL params and navigation
import toast from 'react-hot-toast';
import axios from 'axios';
import { postApiCall } from '../../utils/commonFunctions';

export const UpdateCandidateForm = () => {
    const { id } = useParams(); // Get candidate form ID from the URL params
    const navigate = useNavigate(); // For navigation after submission

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        experience: '',
        post: '',
        city: '',
        area: '',
        resume: null,
        qualification: '',
        specialization: '',
        otherSpecialization: '',
        certifications: '',
        key_skills: ''
    });
    const [loading, setLoading] = useState(false);
    const [isOther, setIsOther] = useState(false); // Handle "Other" specialization
    const [errors, setErrors] = useState({});

    // Fetch candidate form data when the component loads
    useEffect(() => {
        const fetchCandidateForm = async () => {
            try {
                const response = await postApiCall(`/candidateform/show`, { id });
                if (response.data.status === 200) {
                    setFormData(response.data.data); // Populate formData with fetched data
                    if (response.data.data.specialization === "Other") {
                        setIsOther(true); // Show otherSpecialization field if specialization is 'Other'
                    }
                } else {
                    toast.error("Failed to fetch candidate form details.");
                }
            } catch (error) {
                console.error("Error fetching candidate form:", error);
                toast.error("Error fetching candidate form details.");
            }
        };

        fetchCandidateForm();
    }, [id]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone' && value.length > 10) {
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle "Other" specialization
    const handleSpecializationChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({ ...prevData, specialization: value }));

        if (value === "Other") {
            setIsOther(true);
        } else {
            setIsOther(false);
        }
    };

    // Validate form before submitting
    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Email validation
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "A valid email is required.";
            isValid = false;
        }

        // Phone validation (10 digits)
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            tempErrors.phone = "Phone number must be 10 digits.";
            isValid = false;
        }

        // First name validation
        if (!formData.firstName || formData.firstName.length < 2) {
            tempErrors.firstName = "First name must be at least 2 characters.";
            isValid = false;
        }

        // Last name validation
        if (!formData.lastName || formData.lastName.length < 2) {
            tempErrors.lastName = "Last name must be at least 2 characters.";
            isValid = false;
        }

        // Experience validation
        if (!formData.experience) {
            tempErrors.experience = "Experience is required.";
            isValid = false;
        }

        // Resume validation
        if (!formData.resume) {
            tempErrors.resume = "Resume is required.";
            isValid = false;
        }

        // Specialization validation (optional "Other" specialization)
        if (formData.specialization === 'Other' && !formData.otherSpecialization) {
            tempErrors.otherSpecialization = "Please specify your other specialization.";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    // Handle form submission for updating
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Client-side validation before submitting the form
        if (!validateForm()) {
            return;
        }

        setLoading(true);  // Disable button during API call

        try {
            const finalFormData = {
                ...formData,
                id, // Include ID in form data for update
            };

            const response = await postApiCall(`/candidateform/update`, finalFormData);

            if (response.data.status === 200) {
                toast.success("Candidate form updated successfully!");
                navigate("/admin/candidates"); // Navigate to candidate list after update
            } else if (response.data.status === 409) {
                // Handle validation errors returned from the server
                const serverErrors = response.data.errors;
                setErrors(serverErrors);  // Map server errors to form errors
            } else {
                toast.error("Failed to update candidate form.");
            }
        } catch (error) {
            console.error("Error during form update:", error);
            toast.error("An error occurred while updating candidate form.");
        } finally {
            setLoading(false);  // Enable the button once done
        }
    };



    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Update Job Opening</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section className="content p-4">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.email && <small className="text-danger">{errors.email}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="post" className="form-label">Looking for Post</label>
                            <input
                                type="text"
                                className="form-control"
                                id="post"
                                name="post"
                                value={formData.post}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="city" className="form-label">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="area" className="form-label">Area</label>
                            <input
                                type="text"
                                className="form-control"
                                id="area"
                                name="area"
                                value={formData.area}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="experience" className="form-label">Years of Experience</label>
                        <input
                            type="number"
                            className="form-control"
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.experience && <small className="text-danger">{errors.experience}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="qualification" className="form-label">Qualification</label>
                        <select
                            className="form-control"
                            id="qualification"
                            name="qualification"
                            value={formData.qualification}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Qualification</option>
                            <option value="PHD">PHD</option>
                            <option value="Post Graduate">Post Graduate</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Under Graduate">Under Graduate</option>
                            <option value="12th">12th</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="specialization" className="form-label">Specialization</label>
                        <select
                            className="form-control"
                            id="specialization"
                            name="specialization"
                            value={formData.specialization}
                            onChange={handleSpecializationChange}
                            required
                        >
                            <option value="">Select Specialization</option>
                            <option value="MCA">MCA</option>
                            <option value="MSC">MSC</option>
                            <option value="MBA">MBA</option>
                            <option value="BCS">BCS</option>
                            <option value="B.Com">B.Com</option>
                            <option value="BE">BE</option>
                            <option value="BTECH">BTECH</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    {isOther && (
                        <div className="mb-3">
                            <label htmlFor="otherSpecialization" className="form-label">Other Specialization</label>
                            <input
                                type="text"
                                className="form-control"
                                id="otherSpecialization"
                                name="otherSpecialization"
                                value={formData.otherSpecialization}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}

                    <div className="mb-3">
                        <label htmlFor="certifications" className="form-label">Certifications</label>
                        <input
                            type="text"
                            className="form-control"
                            id="certifications"
                            name="certifications"
                            value={formData.certifications}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="key_skills" className="form-label">Key Skills</label>
                        <input
                            type="text"
                            className="form-control"
                            id="key_skills"
                            name="key_skills"
                            value={formData.key_skills}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="resume" className="form-label">Uploaded Resume</label>

                        {/* Display the link to the uploaded resume */}
                        {formData.resume ? (
                            <div className="mt-2">
                                <a href={`${process.env.REACT_APP_FILE_URL}/${formData.resume}`} target="_blank" rel="noopener noreferrer">
                                    View Uploaded Resume
                                </a>
                            </div>
                        ) : (
                            <p>No resume uploaded</p>
                        )}
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Updating...' : 'Update Candidate Form'}
                    </button>
                </form>
            </section>
        </div>
    );
};

export default UpdateCandidateForm;
