import React, { useState } from "react";
import axios from "axios"; // Install axios if you haven't: npm install axios
import toast from "react-hot-toast";
import { postApiCall } from "../utils/commonFunctions";

export const HRForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        companyName: "",
        designation: "",
        location: "",
        requirement: "",
        otherRequirement: "",
        candidatesNeeded: "",
    });
    const [loading, setLoading] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [errors, setErrors] = useState({}); // For validation errors

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone' && value.length > 10) {
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleRequirementChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({ ...prevData, requirement: value }));

        if (value === "Other") {
            setIsOther(true);
        } else {
            setIsOther(false);
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.name) {
            tempErrors.name = "Name is required";
            isValid = false;
        }

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "A valid email is required";
            isValid = false;
        }

        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            tempErrors.phone = "Phone number must be 10 digits";
            isValid = false;
        }

        if (!formData.companyName) {
            tempErrors.companyName = "Company Name is required";
            isValid = false;
        }

        if (!formData.designation) {
            tempErrors.designation = "Designation is required";
            isValid = false;
        }

        if (!formData.location) {
            tempErrors.location = "Location is required";
            isValid = false;
        }

        if (!formData.requirement) {
            tempErrors.requirement = "Requirement is required";
            isValid = false;
        }

        if (isOther && !formData.otherRequirement) {
            tempErrors.otherRequirement = "Other Requirement is required";
            isValid = false;
        }

        if (!formData.candidatesNeeded || formData.candidatesNeeded <= 0) {
            tempErrors.candidatesNeeded = "Candidates Needed must be greater than 0";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form before submission
        if (!validateForm()) {
            return;
        }

        setLoading(true); // Disable the submit button by setting loading to true

        try {
            const response = await postApiCall('/hrform', formData);
            if (response.data.status === 201) {
                toast.success("Form Submitted Successfully!");
                setFormData({
                    name: "",
                    phone: "",
                    email: "",
                    companyName: "",
                    designation: "",
                    location: "",
                    requirement: "",
                    otherRequirement: "",
                    candidatesNeeded: "",
                });
                setIsOther(false); // Reset other requirement field
            } else if (response.data.status === 409) {
                setErrors(response.data.errors); // Specific handling for 409 Conflict errors
            } else {
                toast.error("Failed to submit the form.");
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setErrors(error.response.data.message); // Specific handling for 409 Conflict errors
            } else {
                console.error("Error submitting form:", error);
                toast.error("An error occurred while submitting the form.");
            }
        } finally {
            setLoading(false); // Re-enable the submit button
        }
    };


    return (
        <>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            <h3 className="mb-4">Contact Us</h3>
                            <h1 className="display-4 fw-medium">We’d love to hear from you.</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-5">
                <h2 className="text-center mb-4">HR Application Form</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.name && <small className="text-danger">{errors.name}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.email && <small className="text-danger">{errors.email}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Phone Number</label>
                        <input
                            type="number"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.phone && <small className="text-danger">{errors.phone}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="companyName" className="form-label">Company Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.companyName && <small className="text-danger">{errors.companyName}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="designation" className="form-label">Designation</label>
                        <input
                            type="text"
                            className="form-control"
                            id="designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.designation && <small className="text-danger">{errors.designation}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">Location</label>
                        <input
                            type="text"
                            className="form-control"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.location && <small className="text-danger">{errors.location}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="requirement" className="form-label">Requirement</label>
                        <select
                            className="form-select"
                            id="requirement"
                            name="requirement"
                            value={formData.requirement}
                            onChange={handleRequirementChange}
                            required
                        >
                            <option value="">Select Requirement</option>
                            <option value="Software Engineer">Software Engineer</option>
                            <option value="Data Analyst">Data Analyst</option>
                            <option value="Project Manager">Project Manager</option>
                            <option value="Other">Other</option>
                        </select>
                        {errors.requirement && <small className="text-danger">{errors.requirement}</small>}
                    </div>

                    {isOther && (
                        <div className="mb-3">
                            <label htmlFor="otherRequirement" className="form-label">Other Requirement</label>
                            <input
                                type="text"
                                className="form-control"
                                id="otherRequirement"
                                name="otherRequirement"
                                value={formData.otherRequirement}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.otherRequirement && <small className="text-danger">{errors.otherRequirement}</small>}
                        </div>
                    )}

                    <div className="mb-3">
                        <label htmlFor="candidatesNeeded" className="form-label">How many candidates do you need?</label>
                        <input
                            type="number"
                            className="form-control"
                            id="candidatesNeeded"
                            name="candidatesNeeded"
                            value={formData.candidatesNeeded}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.candidatesNeeded && <small className="text-danger">{errors.candidatesNeeded}</small>}
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>
            <footer className="footer bg-light text-center py-3">
                <p>First Step Technologies Pvt. Ltd. © Copyright 2024</p>
            </footer>
        </>
    );
};
