import React, { useState, useEffect } from 'react';
import { postApiCall } from '../../utils/commonFunctions'; // Assuming this function is available for making API calls
import Pagination from '../Pagination';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const HRList = () => {
  const [hrForms, setHrForms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10); // Set items per page
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query

  useEffect(() => {
    
    fetchHRForms();
  }, [currentPage, itemsPerPage, searchQuery]); // Fetch HR forms whenever page, itemsPerPage, or searchQuery changes
  const fetchHRForms = async () => {
    try {
      const response = await postApiCall('/hrform/index', { // Replace with your actual API endpoint
        page: currentPage,
        limit: itemsPerPage,
        search: searchQuery, // Pass search query to API
      });

      if (response.data.status === 200) {
        setHrForms(response.data.data); // Assuming response contains `hrForms`
        setTotalPages(response.data.pagination.total_pages); // Assuming response contains pagination details
        setTotalRecords(response.data.pagination.total_records); // Assuming response contains pagination details
      } else {
        console.error('Failed to fetch HR forms');
      }
    } catch (error) {
      console.error('Error fetching HR forms:', error);
    }
  };

  // Handler for page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for changing items per page
  const handleItemsPerPageChange = (newLimit) => {
    setItemsPerPage(newLimit);
    setCurrentPage(1); // Reset to first page when items per page is changed
  };

  // Handler for search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Handler for Edit action
  const navigate = useNavigate(); // Initialize navigate hook

  const handleUpdate = (id) => {
    // Navigate to the update form page with the selected HR form ID
    navigate(`/admin/update-hr-form/${id}`);
  };

  // Handler for Delete action
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this HR form?');
    if (confirmDelete) {
      try {
        const response = await postApiCall(`/hrform/delete`, { id }); // Assuming delete endpoint exists
        if (response.data.status === 200) {
          toast.success("Deleted successfully")
          setHrForms(hrForms.filter((form) => form.id !== id)); // Remove deleted form from list
          fetchHRForms()
          console.log(`Deleted HR form with ID: ${id}`);
        } else {
          console.error('Failed to delete HR form');
        }
      } catch (error) {
        console.error('Error deleting HR form:', error);
      }
    }
  };

  // Define CSV headers and data
  const csvHeaders = [
    { label: 'S.No', key: 'index' }, // Added index for serial number
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Company', key: 'companyName' },
    { label: 'Designation', key: 'designation' },
    { label: 'Location', key: 'location' },
    { label: 'Requirement', key: 'requirement' },
    { label: 'Candidates Needed', key: 'candidatesNeeded' }
  ];

  // Map hrForms to include index (S.No) for CSV export
  const csvData = hrForms.map((hrForm, index) => ({
    ...hrForm,
    index: index + 1, // Add S.No (index + 1)
  }));

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">HR Forms</h1>
            </div>{/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      <section className="content">
        {/* Search Input */}
        <div className="row mb-3">
          {/* Search Input */}
          <div className="col-md-8 mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name, Company, or Designation"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          {/* CSV Export Button */}
          <div className="col-md-4 text-end">
            <CSVLink
              headers={csvHeaders}
              data={csvData}
              filename="hr_forms_list.csv"
              className="btn btn-success"
            >
              Export to CSV
            </CSVLink>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Sr.No</th> {/* Add S.No column */}
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Company</th>
                <th>Designation</th>
                <th>Location</th>
                <th>Requirement</th>
                <th>Candidates Needed</th>
                <th>Actions</th> {/* Add actions column */}
              </tr>
            </thead>
            <tbody>
              {hrForms.map((hrForm, index) => (
                <tr key={hrForm.id}>
                  <td>{index + 1}</td> {/* Display serial number (S.No) */}
                  <td>{hrForm.name}</td>
                  <td>{hrForm.phone}</td>
                  <td>{hrForm.email}</td>
                  <td>{hrForm.companyName}</td>
                  <td>{hrForm.designation}</td>
                  <td>{hrForm.location}</td>
                  <td>{hrForm.requirement}</td>
                  <td>{hrForm.candidatesNeeded}</td>
                  <td>
                    <FaEdit className="me-3 text-warning" style={{ cursor: 'pointer' }} onClick={() => handleUpdate(hrForm.id)} title="Edit" />
                    <FaTrash className="text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(hrForm.id)} title="Delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </section>
    </div>
  );
};

export default HRList;
