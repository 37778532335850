import React from "react";
import './Home.css';
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

export const Home = () => {
    const { ref: countUpRef1, inView: inView1 } = useInView({ triggerOnce: true });
    const { ref: countUpRef2, inView: inView2 } = useInView({ triggerOnce: true });
    const { ref: countUpRef3, inView: inView3 } = useInView({ triggerOnce: true });
    const { ref: countUpRef4, inView: inView4 } = useInView({ triggerOnce: true });
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/current-opening");  // Change the path based on your route setup
    };
    return (
        <div>
            {/* Hero Section */}
            <section className="hero-section position-relative text-white d-flex align-items-center justify-content-center mt-5 pt-3">
                <div className="overlay position-absolute w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                <div className="container d-flex align-items-center text-white" style={{ height: '100vh', position: 'relative', zIndex: '2' }}>
                    <div className="bg-dark-opacity rounded" style={{ background: 'rgba(0, 0, 0, 0.0)', zIndex: '3' }}>
                        <p className="subtitle mb-3">SERVICE WITH A HUMAN TOUCH</p>
                        <h1 className="display-4 title">High quality solutions <br /> On time, On budget</h1>
                        <div className="d-flex flex-row mt-4">
                            <button onClick={handleNavigate} className="btn btn-danger btn-lg me-2">
                                Looking for a job?
                            </button>
                            <button onClick={() => navigate('/hr-form')} className="btn btn-danger btn-lg me-2">Are you looking for candidate?</button>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6 mb-4">
                        {/* <h2 className="about-title">About First Step</h2>
                        <p>
                            First Step is a client-centric organization, offering a diverse range of services tailored to meet the specific needs of businesses.
                        </p>
                        <p>
                            The backbone of First Step is our leadership team, with over 10 years of experience in the IT industry and a proven track record in top-tier listed companies.
                        </p>
                        <p>
                            We recognize that people are the most valuable assets, and having the right talent is key to achieving operational excellence within any organization.
                        </p> */}
                        <h2 className="about-title">About First Step</h2>
                        <p>
                            First Step is a recruitment-focused organization with over 10 years of experience in matching businesses with the right talent. We offer a diverse range of staffing solutions tailored to meet the unique needs of each client.
                        </p>
                        <p>
                            Our leadership team brings a wealth of experience from top-tier listed companies in the IT industry, ensuring a strategic approach to recruitment that emphasizes quality and operational excellence.
                        </p>
                        <p>
                            We recognize that people are an organization’s most valuable asset, and our mission is to support our clients in achieving their goals by connecting them with high-caliber professionals.
                        </p>

                    </div>
                    <div className="col-md-6">
                        <h2 className="about-title">Our Vision</h2>
                        <p>
                            To become a globally recognized services organization known for delivering high-quality solutions and ensuring complete client satisfaction.
                        </p>
                        <h2 className="about-title">Our Mission</h2>
                        <ul>
                            <li>Build and nurture lasting relationships through dedicated efforts.</li>
                            <li>Address complexities with simple and effective solutions.</li>
                            <li>Consistently innovate to provide cost-efficient services.</li>
                        </ul>
                    </div>
                </div>
            </div>


            {/* Services Section */}
            <div className="services-section align-items-center py-5">
                <h4 className="mb-5 text-center">OUR PLACEMENT SERVICE</h4>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-user-plus service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>PERMANENT PLACEMENT</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-users service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>CONTRACTUAL STAFFING</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-laptop-code service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>IT</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-flask service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>PHARMA</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-utensils service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>HOSPITALITY</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-shopping-cart service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>RETAIL</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-headset service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>BPO</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-home service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>REAL ESTATE</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-industry service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>MANUFACTURING</h2>
                            </div>
                        </div>
                    </div>

                    <h4 className="mb-5 mt-4 text-center">OTHER SERVICE</h4>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-building service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>REAL ESTATE</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-bullhorn service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>DIGITAL MARKETING</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-truck service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>DROP SHIPPING</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-code service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>WEB DESIGN</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-paint-brush service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>INTERIOR DESIGNER </h2>
                            </div>
                        </div>

                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-hammer service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>CONSTRUCTION</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <i className="fas fa-dollar-sign service-icon me-3" style={{ fontSize: '30px', minWidth: '50px' }}></i>
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>FINANCE</h2>
                            </div>
                        </div>

                    </div>
                    <h4 className="mb-5 mt-4 text-center">OUR CLIENTS</h4>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/jd.png')} alt="Justdial Ltd" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Justdial Ltd</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/ebixcash.jpeg')} alt="Ebixcash Global Services" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Ebixcash Global Services</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/hdfc.png')} alt="HDFC Bank Ltd" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>HDFC Bank Ltd</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/icici.png')} alt="ICICI Prudential" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>ICICI Prudential</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/icicibank.png')} alt="ICICI Bank" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>ICICI Bank</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/altruist.png')} alt="Altruist Technology Pvt Ltd" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Altruist Technology Pvt Ltd</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <img src={require('../assets/senwell.jpeg')} alt="Senwell Group Pvt Ltd" className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Senwell Group Pvt Ltd</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <div className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Adnoc Ltd</h2>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex align-items-center">
                                <div className="client-logo me-3" style={{ width: '50px', minWidth: '50px' }} />
                                <h2 className="service-title mb-0" style={{ fontSize: '20px' }}>Titan Financial Brokers</h2>
                            </div>
                        </div>
                    </div>


                </div>
            </div>



            {/* Business Philosophy Section */}
            <div className="business-philosophy text-center py-5">
                <h4 className="mb-3">Our Business Philosophy</h4>
                <p className="lead">We are driven by a commitment to excellence, integrity, and respect for all our stakeholders.</p>
            </div>

            {/* Facts Section */}
            <div className="facts bg-light py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center mb-4">
                            <i className="bi bi-house fs-1"></i>
                            <h3 ref={countUpRef1}>{inView1 ? <CountUp end={10} /> : 0}</h3>
                            <p>Years of Experience</p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center mb-4">
                            <i className="bi bi-people fs-1"></i>
                            <h3 ref={countUpRef2}>{inView2 ? <CountUp end={840} /> : 0}</h3>
                            <p>Professionals Placed</p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center mb-4">
                            <i className="bi bi-building fs-1"></i>
                            <h3 ref={countUpRef3}>{inView3 ? <CountUp end={2} /> : 0}</h3>
                            <p>Offices</p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center mb-4">
                            <i className="bi bi-pie-chart fs-1"></i>
                            <h3 ref={countUpRef4}>
                                {inView4 ? <><CountUp end={89} />%</> : "0%"}
                            </h3>
                            <p>of business from existing clients</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-white py-3 text-center">
                <p>First Step Technologies Pvt. Ltd. © Copyright 2024</p>
            </footer>
        </div>
    );
};
