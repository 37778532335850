import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const postApiCall = async (endpoint, data) => {
  try {
    // Get base URL from environment variables
    console.log(baseUrl);

    // Prepare headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Prepare payload
    let payload = data ? { ...data } : {};

    // Make API request with the provided data
    // console.log("Payload:", payload);
    const resp = await axios.post(`${baseUrl}${endpoint}`, payload, { headers });
    return resp;

  } catch (error) {
    // Return the raw error response without handling specific cases
    return error.response || error;
  }
};
