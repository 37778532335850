import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // For fetching the job ID from URL
import axios from 'axios'; // For API requests
import { postApiCall } from '../../utils/commonFunctions';
import toast from 'react-hot-toast';

const UpdateJobOpening = () => {
    const { id } = useParams(); // Get the job ID from the route parameter
    const navigate = useNavigate(); // To redirect after successful update
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        jobTitle: '',
        jobDescription: '',
        location: '',
        experience: '',
        jobType: '',
        education: '',
        numberOfVacancies: '',
    });
    const [errors, setErrors] = useState({}); // State to track validation errors

    useEffect(() => {
        // Fetch the job data from the API and populate the form
        const fetchJobData = async () => {
            try {
                const response = await postApiCall('/jobs/show', { id });
                if (response.data.status === 200) {
                    setFormData(response.data.data); // Assuming API returns job data
                }
            } catch (error) {
                toast.error("Failed to load job details.");
            }
        };

        fetchJobData();
    }, [id]);

    const validate = () => {
        const newErrors = {};
        if (!formData.jobTitle.trim()) newErrors.jobTitle = 'Job title is required';
        if (!formData.jobDescription.trim()) newErrors.jobDescription = 'Job description is required';
        if (!formData.location.trim()) newErrors.location = 'Location is required';
        if (!formData.experience ) newErrors.experience = 'Experience is required';
        if (!formData.jobType) newErrors.jobType = 'Job type is required';
        if (!formData.numberOfVacancies || formData.numberOfVacancies <= 0) newErrors.numberOfVacancies = 'Number of vacancies must be greater than 0';
        return newErrors;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form before submitting
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);
        try {
            // Call the API to update the job data
            const response = await postApiCall('/jobs/update', formData);

            if (response.data.status === 200) {
                toast.success("Job opening has been updated successfully!");
                navigate('/admin/job-openings'); // Redirect to job list after successful update
            } else if (response.data.status === 409) {
                const serverErrors = response.data.errors;
                setErrors(serverErrors);  // Set validation errors in the form
                toast.error("Validation errors occurred. Please fix them and try again.");
            }else{
                toast.error("Failed to update job. Please try again.");
            }
        } catch (error) {

            toast.error("Failed to update job. Please try again.");

        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Update Job Opening</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section className="content p-4">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="jobTitle" className="form-label">
                            Job Title*
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                            id="jobTitle"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.jobTitle && <div className="invalid-feedback">{errors.jobTitle}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="jobDescription" className="form-label">
                            Job Description*
                        </label>
                        <textarea
                            className={`form-control ${errors.jobDescription ? 'is-invalid' : ''}`}
                            id="jobDescription"
                            name="jobDescription"
                            rows="4"
                            value={formData.jobDescription}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                        {errors.jobDescription && <div className="invalid-feedback">{errors.jobDescription}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">
                            Location*
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.location ? 'is-invalid' : ''}`}
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.location && <div className="invalid-feedback">{errors.location}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="experience" className="form-label">
                            Experience Required (in years)*
                        </label>
                        <input
                            type="number"
                            className={`form-control ${errors.experience ? 'is-invalid' : ''}`}
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.experience && <div className="invalid-feedback">{errors.experience}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="jobType" className="form-label">
                            Job Type*
                        </label>
                        <select
                            className={`form-select ${errors.jobType ? 'is-invalid' : ''}`}
                            id="jobType"
                            name="jobType"
                            value={formData.jobType}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Job Type</option>
                            <option value="Full-time">Full-time</option>
                            <option value="Part-time">Part-time</option>
                            <option value="Contract">Contract</option>
                        </select>
                        {errors.jobType && <div className="invalid-feedback">{errors.jobType}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="education" className="form-label">
                            Education Requirement
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="education"
                            name="education"
                            value={formData.education}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="numberOfVacancies" className="form-label">
                            Number of Vacancies*
                        </label>
                        <input
                            type="number"
                            className={`form-control ${errors.numberOfVacancies ? 'is-invalid' : ''}`}
                            id="numberOfVacancies"
                            name="numberOfVacancies"
                            value={formData.numberOfVacancies}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.numberOfVacancies && <div className="invalid-feedback">{errors.numberOfVacancies}</div>}
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? "Updating..." : "Update Job Opening"}
                    </button>
                </form>
            </section>
        </div>
    );
};

export default UpdateJobOpening;
