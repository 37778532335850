import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { postApiCall } from '../utils/commonFunctions';
import toast from 'react-hot-toast';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // For displaying error messages
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Check if the token exists in local storage
    const token = localStorage.getItem('authToken');
    if (token) {
      // If token exists, redirect to admin dashboard
      navigate('/admin/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // API call to validate the login
      const response = await postApiCall('/login', {
        username: email,
        password
      });
      // If login is successful, navigate to the admin dashboard
      if (response.data.status === 200) {

        toast.success("Login Successfully");

        // Store the token in localStorage (assumes token is in response.data.token)
        const token = response.data.token || 'randomToken'; // Use 'randomToken' as fallback
        localStorage.setItem('authToken', token); // Store the token in localStorage
        navigate('/admin/dashboard');

      } else {
        // Display error message if login is unsuccessful
        setErrorMessage('Invalid login credentials');
      }
    } catch (error) {
      console.error('Login error:', error);
      // Set error message if the API call fails or the response is invalid
      setErrorMessage('An error occurred while logging in. Please try again.');
    }
  };

  return (
    <div className="container">
      <h2 className="text-center my-5">Admin Login</h2>
      <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: '400px' }}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email address</label>
          <input
            type="username"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {/* Display error message if login fails */}
        {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
        <button type="submit" className="btn btn-primary w-100">Login</button>
      </form>
    </div>
  );
};

export default Login;
