import React, { useEffect, useState } from 'react';
import { postApiCall } from '../../utils/commonFunctions'; // Assuming postApiCall is your function for making API requests
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

function Dashboard() {
    const [counts, setCounts] = useState({
        jobCount: 0,
        hrFormCount: 0,
        candidateFormCount: 0,
    });

    // Fetch counts from the API when the component mounts
    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await postApiCall('/dashboard'); // Call your API to fetch counts
                if (response.data.status === 200) {
                    setCounts(response.data.data); // Set the counts in state
                } else {
                    toast.error('Failed to fetch counts');
                }
            } catch (error) {
                console.error('Error fetching counts:', error);
                toast.error('An error occurred while fetching counts');
            }
        };

        fetchCounts();
    }, []);

    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard v1</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    {/* Small boxes (Stat box) */}
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{counts.jobCount}</h3>
                                    <p>Jobs</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-briefcase" />
                                </div>
                                <Link to="/admin/job-openings" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{counts.hrFormCount}</h3>
                                    <p>HR Forms</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-document" />
                                </div>
                                <Link to="/admin/hr-forms" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{counts.candidateFormCount}</h3>
                                    <p>Candidate Forms</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <Link to="/admin/candidates" className="small-box-footer">
                                    More info <i className="fas fa-arrow-circle-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Dashboard;
