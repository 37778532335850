import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { Navigation } from "./screens/Navigation";
import { Home } from "./screens/Home";
import { About } from "./screens/About";
import { Recruitment } from "./screens/Recruitment";
import { Corporate } from "./screens/Corporate";
import { Contact } from "./screens/Contact";
import { Candidate } from "./screens/Candidate";
import { HRForm } from "./screens/HRForm";
import AdminPanel from './component/admin/AdminPanel'; // Admin Panel
import Login from './screens/Login'; // Login Component
import Current from "./screens/Current";
import JobDetails from "./screens/JobDetails";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from './component/ProtectedRoute'; // Import the ProtectedRoute

// Custom component to conditionally render Navigation
const MainLayout = ({ children }) => {
  const location = useLocation();

  // Hide navigation for specific routes
  const hideNavigationRoutes = ["/admin", "/login"];
  const shouldHideNav = hideNavigationRoutes.some((route) => location.pathname.startsWith(route));

  return (
    <>
      {!shouldHideNav && <Navigation />}
      {children}
    </>
  );
};

const App = () => {
  return (
    <Router>
       <Toaster />
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/recruitment-service" element={<Recruitment />} />
          <Route path="/corporate-training" element={<Corporate />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/candidate-form" element={<Candidate />} />
          <Route path="/current-opening" element={<Current />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/hr-form" element={<HRForm />} />
          <Route path="/admin/*" element={
            <ProtectedRoute> {/* Protect the admin route */}
              <AdminPanel />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login />} /> {/* Login Page */}
        </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;
